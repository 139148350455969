.mask {
  width: 100%;
  height: 100vh;
  position: relative;
}

.intro-img {
  width: 100%;
  height: 120vh;
  object-fit: cover;
  background-position: center;
}

.mask::after {
  content: "";
  width: 100%;
  height: 120vh;
  position: absolute;
  top: 0;
  left: 0;
}

.hero {
  height: 120vh;
  width: 100%;
}

.hero .content {
  position: absolute;
  transform: translate(-50%, -65%);
  top: 50%;
  left: 50%;
  text-align: center;
  width: 90%;
  padding-top: 750px;
}

.hero .content h1 {
  font-size: 5.5rem;
  transition: 0.5s;
}

.hero .content p {
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.content .btn {
  margin: 1rem 0.2rem;
  color: black;
}

/* Keyframes */

/* Responsive */

@media screen and (max-width: 640px) {
  .intro-img {
    object-position: 40% center; /* Décale l'image vers la droite */
  }

  .hero .content h1 {
    font-size: 2.7rem;
  }
}

@media screen and (min-width: 1500px) {
  .hero .content {
    padding-top: 750px;
  }
}

@media screen and (min-width: 640px) and (max-width: 1080px) {
  .intro-img {
    object-position: 40% center; /* Décale l'image vers la droite */
  }
  .hero .content {
    padding-top: 750px;
  }
}
